import React from 'react';

import {
    BrowserRouter as ReactRouter,
    Route,
    Switch, Redirect
} from 'react-router-dom';

import App from './App';
// pages
import Index from "views/index.js";
import About from "views/about.js";
import Contact from "views/contact.js";
import Costumers from "views/costumers.js";
import Portfolio from "views/portfolio.js";
import Services from "views/services.js";
import Service from "views/service.js";

import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'

const userSignedIn = false;

class Router extends React.Component {
    signedInRoutes() {
        if (this.props.user.jwt) {
            return (
                <Route path="/new" component={About} />
            );
        }
    }

    home() {
        if (this.props.user.jwt) {
            return About;
        }
        return Index;
    }

    render() {
        return (
            <ConnectedRouter history={this.props.history}>
                <App>
                    <Switch>
                        <Route exact path="/index" component={this.home()}></Route>
                        <Route path="/about" component={About}></Route>
                        <Route path="/contact" component={Contact}></Route>
                        <Route path="/costumers" component={Costumers}></Route>
                        <Route path="/portfolio" component={Portfolio}></Route>
                        <Route path="/services/:slug" component={Service}></Route>
                        <Route path="/services" component={Services}></Route>
                        {this.signedInRoutes()}
                        <Redirect to="/index" />
                    </Switch>

                </App>
            </ConnectedRouter>
        )
    }
}



function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
        business: state.business
    }
}
export default connect(mapStateToProps)(Router);