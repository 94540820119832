import React from "react";
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
const select = (el, all = false) => {
    el = el.trim()
    if (all) {
      return [...document.querySelectorAll(el)]
    } else {
      return document.querySelector(el)
    }
  }
/**
 * Easy event listener function
 */
const on = (type, el, listener, all = false) => {
  let selectEl = select(el, all)
  if (selectEl) {
    if (all) {
      selectEl.forEach(e => e.addEventListener(type, listener))
    } else {
      selectEl.addEventListener(type, listener)
    }
  }
}
export default class LinkContainer extends React.Component {
    animation() {
      /**
       * Mobile nav toggle
       */
      on('click', '.mobile-nav-toggle', function (e) {
        select('#navbar').classList.toggle('navbar-mobile')
        this.classList.toggle('bi-list')
        this.classList.toggle('bi-x')
      })
  
      /**
       * Mobile nav dropdowns activate
       */
      on('click', '.navbar .dropdown > a', function (e) {
        if (select('#navbar').classList.contains('navbar-mobile')) {
          e.preventDefault()
          this.nextElementSibling.classList.toggle('dropdown-active')
        }
      }, true)
    }
  
    componentDidMount() {
        //this.animation();
      }
      componentDidUpdate() {
        //this.animation();
      }
    FirtsLink(link) {
        var isActive = window.location.pathname === this.props.link.hrefLink;
        var activeLink = isActive ? 'active' : '';
        if (link.links !== undefined) {
            if (link.links.length > 0) {
                return (<a href={link.hrefLink}><span>{link.title}</span> <i className="bi bi-chevron-down"></i></a>);
            }
        } else {
            return (<a className={`nav-link scrollto ${activeLink}`} href={link.hrefLink}>{link.title}</a>);
        }
    }
    SubLink(link, index) {
        return (<li key={index}><a href={link.hrefLink}>{link.title}</a></li>);
    }
    SubLinks(link) {
        if (link.links !== undefined) {
            return (<ul>
                {link.links.map((subLink, index) => {
                    return this.SubLink(subLink, index);
                })}
            </ul>);
        }
    }
    render() {
        let liclass = "";
        if (this.props.link.links !== undefined) {
            if (this.props.link.links.length > 0) {
                liclass = "dropdown";
            }
        }

        return (
          <Nav.Link href={this.props.link.hrefLink}>{this.props.link.title}</Nav.Link>

        );
    }
}