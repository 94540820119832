import React, { Component } from 'react';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import CSSTransition from 'react-transition-group/CSSTransition';
import { connect } from 'react-redux';

import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
/*Componentes*/
import Header from "./components/header/header.js";
import TopBar from "./components/topBar.js";
import Footer from "./components/footer.js";
import ArrowUp from "./components/arrowUp.js";
import ButtonWhatsapp from "./components/buttonWhatsapp.js";
import SectionContact from "./components/sectionContact.js";
import * as actionsBusiness from './actions/businessActions'
import * as actionsLinks from './actions/linksActions'

import './assets/css/style.css';

class App extends Component {

    constructor(props) {
        super(props);
        this.loadLinks();
        this.loadBusiness();
        //console.log('props',window.location.pathname.split('/')[1]);

    }
    componentDid() {
    }
    loadBusiness() {
        this.props.dispatch(actionsBusiness.loadAll());
    }
    loadLinks() {
        this.props.dispatch(actionsLinks.loadAll());
    }

    goHome() {
        this.props.history.push('/');
    }
    ightboxanimate() {
    }
    render() {
        return (
            <div>
                {this.props.business && [<TopBar key={1} business={this.props.business} />]}
                <Header links={this.props.links} />
                
                <TransitionGroup>
                    <CSSTransition classNames="left-out" timeout={300}
                        key={window.location.pathname.split('/')[1]}>
                        {this.props.children}
                    </CSSTransition>
                </TransitionGroup>
                <ButtonWhatsapp linkWahtsap={this.props.business.whatsApp} />
                <ArrowUp />
                <SectionContact key={2} business={this.props.business} />
                <Footer />
            </div>
        );
    }
}
function mapStateToProps(state, ownProps) {
    return {
        business: state.business,
        links: state.links,
    }
}

export default connect(mapStateToProps)(App);