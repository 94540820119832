import React from "react";

const select = (el, all = false) => {
  el = el.trim()
  if (all) {
    return [...document.querySelectorAll(el)]
  } else {
    return document.querySelector(el)
  }
}
const onscroll = (el, listener) => {
  el.addEventListener('scroll', listener)
}
export default class ArrowUp extends React.Component {

  animation() {
    const backtotop = select('.back-to-top')
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add('active')
        } else {
          backtotop.classList.remove('active')
        }
      }
      window.addEventListener('load', toggleBacktotop)
      onscroll(document, toggleBacktotop)
    }

  }
  componentDidMount() {
    this.animation();
  }
  render() {
    return (
      <a key={1} href={`${window.location.pathname.split('/')[1]}#`} className="back-to-top d-flex align-items-center justify-content-center">
        <i key={1} className="bi bi-arrow-up-short"></i>
      </a>
    );
  }
}