import React from "react";
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'

export default class TopBar extends React.Component {
  guion(i) {
    if (i > 0) {
      return " - "
    };
  }

  render() {
    return (
      <header className="top-bar">
        <Navbar id="top-bar" expand="sm" className=" justify-content-center navbar navbar-expand navbar-dark">
          <Navbar.Collapse>
            <Navbar.Text>
              <a title="Facebook" href={this.props.business.facebook} target="_bank" rel="noreferrer">
                <i className="bx bxl-facebook-square" aria-hidden="true"></i>
              </a>
            </Navbar.Text>
            <Navbar.Text>
              <a href={this.props.business.whatsApp} target="_bank">
                <i className="bx bxl-whatsapp" aria-hidden="true"></i>
              </a>
            </Navbar.Text>
            <Navbar.Text className="group-nav">
              <i className="bx bx-phone" aria-hidden="true"></i>
              {
                this.props.business.phones.map((phone, index) => {
                  return (
                    <a key={index} href={`tel:${phone}`}>{phone}</a>

                  )
                }
                )
              }</Navbar.Text>
            <Navbar.Text className="group-nav">
            <i className="bx bx-envelope" aria-hidden="true"></i>
            {
              this.props.business.emails.map((email, index) => {
                return (
                  <a key={index} href={`mailto:${email}`}>
                    {email}</a>
                )
              }
              )
            }
            </Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
      </header>
    );
  }
}

