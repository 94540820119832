import React from "react";
import { Breadcrumb } from 'react-bootstrap'

export default class Bbreadcrumbs extends React.Component {

  render() {
    const rutas = window.location.pathname.split('/');
    return (
      <Breadcrumb>
        <Breadcrumb.Item href="inico">Inicio</Breadcrumb.Item>
        {

          rutas.map((ruta, index) => {
            if (ruta !== "") {
              if (rutas.length - 1 == index) {
               return( <Breadcrumb.Item key={index} active>{ruta}</Breadcrumb.Item>)
              } else {
                return (
                  <Breadcrumb.Item key={index} href={ruta}>
                    {ruta}
                  </Breadcrumb.Item>
                )
              }
            }
          }
          )
        }
      </Breadcrumb>
    );
  }
}